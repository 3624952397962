import { BrowserRouter, Routes, Route } from "react-router-dom"
import { createContext, useState } from 'react'

import Header from "./Components/Header"
import Footer from "./Components/Footer"
import Mainpage from "./Components/Mainpage"
import NotFound from "./Components/NotFound"
import ContentArea from "./Components/ContentArea"
import { fromLocalStorage, ILocale } from "../Locales"

import ContactPage from "./Components/ContactPage"
import ComponentsPage from "./Components/ComponentsPage"

type TLocaleControls = {
    locale: ILocale,
    setLocale: (localeKey: string) => void
}
type IApplicationProps = { locale: ILocale, props?: {} }

export const LocaleContext = createContext<TLocaleControls>({} as TLocaleControls)

const App = ({locale, ...props}: IApplicationProps) => {
    const [stateLocale, setStateLocale] = useState('en')
    const savedLanguage = fromLocalStorage();

    if (!savedLanguage) {
        locale.setLanguage(stateLocale)
        setLocalStorageLanguage(stateLocale)
    } else {
        locale.setLanguage(savedLanguage)
    }

    function setLocalStorageLanguage(localeKey: string) {
        localStorage.setItem('lang', localeKey)
    }
    
    function setLocaleHandler(localeKey: string) {
        locale.setLanguage(localeKey)
        setStateLocale(localeKey)
        setLocalStorageLanguage(localeKey)
    }

    const localeControls: TLocaleControls = {
        locale,
        setLocale: setLocaleHandler
    }

    return (
        <LocaleContext.Provider value={localeControls}>
            <BrowserRouter basename="/">
                <Header />
                <Routes>
                    <Route path="/" element={<ContentArea />}>
                        <Route index element={<Mainpage />} />
                        <Route path="contact" element={<ContactPage />} />
                        <Route path="dev/components" element={<ComponentsPage />} />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
                <Footer />
            </BrowserRouter>
        </LocaleContext.Provider>
    )
} 

export default App