import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './app'
import locales from './Locales'

const rootElement: HTMLElement | null = document.getElementById('root')
const application = (
    <React.StrictMode>
        <App locale={locales} />
    </React.StrictMode>
)

ReactDOM.render(application, rootElement)