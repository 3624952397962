import styles from './Mainpage.module.scss'
import Hero from '../Hero'
import Skills from '../Skills'
import Works from '../Works'
import Contacts from '../Contacts'
import NotWar from '../NotWar'

const Mainpage = ({...props}) => {
    const showNotWar = false
    return (
        <section className={styles.Mainpage}>
            <Hero />
            <Skills />
            <Works />
            <Contacts />
            { showNotWar ? <NotWar /> : null }
        </section>
    )
}

export default Mainpage