import styles from './ComponentsPage.module.scss'
import PageContainer from '../PageContainer'

import { InputText, Textarea, RadioInput, CheckboxInput } from '../UI/Inputs'
import { Button } from '../UI/Buttons'
import Typography, { Sublinks, TextSpoiler } from '../UI/Typography'

const ComponentsPage = ({...props}) => {
    return (
        <section className={styles.ComponentsPage}>
            <PageContainer elementClasses={[styles.ComponentsPage__container]}>
                <div className={styles.ComponentsPage__pageTitle}>Components</div>
                <div className={styles.ComponentsPage__block}>
                    <div className={styles.ComponentsPage__title}>Input Text</div>
                    <InputText label='Base Input' elementStyles={{marginBottom: '10px'}} value='Привет мир' />
                    <InputText error={true} label='Error Input' elementStyles={{marginBottom: '10px'}} />
                    <InputText disabled={true} label='Disabled Input' value='Привет мир' />
                </div>
                <div className={styles.ComponentsPage__block}>
                    <div className={styles.ComponentsPage__title}>Textarea</div>
                    <Textarea label='Base textarea' elementStyles={{marginBottom: '10px'}} />
                    <Textarea error={true} label='Error Textarea' elementStyles={{marginBottom: '10px'}} />
                    <Textarea disabled={true} label='Disabled Textarea' />
                </div>
                <div className={styles.ComponentsPage__block}>
                    <div className={styles.ComponentsPage__title}>Buttons</div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexWrap: 'wrap', gap: '15px'}}>
                        <Button theme='primary'>Primary Button</Button>
                        <Button theme='primary' disabled={true}>Disabled Primary Button</Button>
                        <Button theme='alert'>Button Alert</Button>
                        <Button theme='alert' disabled={true}>Alert Disabled Button</Button>
                        <Button theme='link'>Button link</Button>
                        <Button theme='link' disabled={true}>Disabled Link Button</Button>
                    </div>
                </div>
                <div className={styles.ComponentsPage__block}>
                    <div className={styles.ComponentsPage__title}>Radio Primary</div>
                    <div className={styles.ComponentsPage__radios}>
                        <RadioInput label='Input type radio' value='radio-1' name='radio1' />
                        <RadioInput label='Input type radio' value='radio-2' name='radio1' />
                        <RadioInput label='Input type radio' value='radio-3' name='radio1' />
                    </div>
                    <div className={styles.ComponentsPage__title}>Radio Error</div>
                    <div className={styles.ComponentsPage__radios}>
                        <RadioInput label='Input type radio' error={true} value='radio-1' name='radio2' />
                        <RadioInput label='Input type radio' error={true} value='radio-2' name='radio2' />
                        <RadioInput label='Input type radio' error={true} value='radio-3' name='radio2' />
                    </div>
                </div>
                <div className={styles.ComponentsPage__block}>
                    <div className={styles.ComponentsPage__title}>Checkbox Primary</div>
                    <div className={styles.ComponentsPage__radios}>
                        <CheckboxInput label='Input type checkbox 1' value='checkbox-1' name='checkbox1' />
                        <CheckboxInput label='Input type checkbox 2' value='checkbox-2' name='checkbox1' />
                        <CheckboxInput label='Input type checkbox 3' value='checkbox-3' name='checkbox1' />
                    </div>
                    <div className={styles.ComponentsPage__title}>Checkbox Error</div>
                    <div className={styles.ComponentsPage__radios}>
                        <CheckboxInput label='Input type checkbox 1' error={true} value='checkbox-1' name='checkbox1' />
                        <CheckboxInput label='Input type checkbox 2' error={true} value='checkbox-2' name='checkbox1' />
                        <CheckboxInput label='Input type checkbox 3' error={true} value='checkbox-3' name='checkbox1' />
                    </div>
                </div>
                <div className={[styles.ComponentsPage__block, styles.ComponentsPage__block__fullpage].join(' ')}>
                    <div className={styles.ComponentsPage__title}>Typography</div>
                    <Typography>
                        <h1>Title H1</h1>
                        <h2>Title H2</h2>
                        <p>Lorem <a href="/dev/components">ipsum</a> dolor sit amet consectetur adipisicing elit. Quidem asperiores, harum vel alias, distinctio fugit repudiandae ad ut dicta unde minus tempora! Alias ea veritatis molestiae labore nihil dolores obcaecati.</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit rerum illo quis harum autem ex eaque perspiciatis repellat delectus nam perferendis facere, voluptatibus aliquam fugit soluta dolorum officia natus asperiores.</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque id nobis quibusdam dolor in enim deleniti reiciendis. <a href="/dev/components">Facere odit error, ipsum ad molestias, eligendi eos nulla ut placeat</a>, laborum praesentium?</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non quasi, facilis repellendus, sint laborum cupiditate in exercitationem, vel alias eum asperiores eius modi sapiente numquam molestias amet nisi iure quisquam!</p>
                        <TextSpoiler label='Lorem?'>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ab explicabo saepe blanditiis neque. Culpa earum vel repudiandae, possimus tenetur voluptates error temporibus ducimus, voluptatum cumque obcaecati, nihil accusamus necessitatibus reprehenderit.</p>    
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate aliquid magnam dolor reiciendis a qui laudantium minima aut aliquam, quaerat ea consequuntur quasi deserunt error itaque provident, ullam, sed suscipit.</p>
                        </TextSpoiler>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Velit sit laboriosam non accusantium. Atque quis modi facilis autem harum rem sequi facere quaerat, sapiente incidunt mollitia, quos in numquam quisquam.</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis nulla velit totam facilis ad quam inventore repudiandae neque, sapiente soluta sequi commodi pariatur aperiam deleniti dolorum ea cumque. Exercitationem, nulla.</p>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea qui error facere animi non vitae unde dignissimos esse eum incidunt praesentium magnam doloribus culpa ex, vel reiciendis quo. Officiis autem beatae quasi optio fugit quam nisi repellendus laboriosam ipsam dolorem dolore cupiditate velit obcaecati maiores quis cumque quisquam recusandae, nihil veniam. Facere iste accusamus, autem rem a voluptas vel tenetur dicta tempora impedit quo numquam ut esse, natus illo. In exercitationem tenetur ullam dolorum odit reprehenderit quam sequi quasi repudiandae doloribus qui, perspiciatis laboriosam autem quo illo quisquam impedit laborum porro ratione? Saepe fugiat velit ipsum, unde optio obcaecati odit.</p>
                        <ul>
                            <li>Lorem, ipsum dolor.</li>
                            <li>Lorem ipsum dolor sit amet.</li>
                            <li>Lorem, ipsum.</li>
                            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut aspernatur et aliquam sint ipsam, alias asperiores, delectus, atque exercitationem nemo illo tempora magni porro eum quia? Vel adipisci eaque aperiam!</li>
                        </ul>
                        <ol>
                            <li>Lorem, ipsum dolor.</li>
                            <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</li>
                            <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error numquam, repellat minima alias quas repudiandae provident? Sapiente corporis porro, expedita perferendis ullam, exercitationem ea ratione necessitatibus blanditiis rerum aperiam laudantium.</li>
                            <li>Lorem, ipsum.</li>
                        </ol>
                        <Sublinks 
                            label='Sublinks'
                            links={[
                                {
                                    title: 'Test',
                                    subtitle: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Enim ullam culpa temporibus quis quia iure! Harum facere earum dicta alias quidem velit enim iure repudiandae, tenetur cum officiis in delectus.',
                                    link: '/dev/components'
                                },
                                {
                                    title: 'Test',
                                    subtitle: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Enim ullam culpa temporibus quis quia iure! Harum facere earum dicta alias quidem velit enim iure repudiandae, tenetur cum officiis in delectus.',
                                    link: '/dev/components'
                                },
                                {
                                    title: 'Test2',
                                    subtitle: 'Test3',
                                    link: '/dev/components'
                                }
                            ]}
                        />
                    </Typography>
                </div>
            </PageContainer>
        </section>
    )
}

export default ComponentsPage