import styles from './Header.module.scss'
import PageContainer from '../PageContainer'
import Logo from '../UI/Logo'
import HeaderNavigation from '../UI/HeaderNavigation'

import { LocaleContext } from '../..'
import { useContext } from 'react'
import type { Navigation } from '../../Types/types'

const Header = ({...props}) => {
    const localeControls = useContext(LocaleContext)    
    const localeStrings: any = localeControls.locale.getString('navigation')

    const navigationHeader: Navigation = {
        items: [
            {
                id: 1,
                title: localeStrings.portfolio,
                to: '/portfolio/',
                type: 'navlink',
                visible: false,
            },
            {
                id: 2,
                title: localeStrings.contacts,
                to: '/contacts/',
                type: 'navlink',
                visible: false,
            },
            {
                id: 3,
                title: localeStrings.github,
                to: 'https://github.com/vkdg',
                type: 'a',
                target: '_blank',
                icon: 'fab fa-github',
            }
        ]
    }

    return (
        <header className={styles.Header}>
            <PageContainer>
                <div className={styles.Header__wrapper}>
                    <Logo title={localeControls.locale.getString("header.title")} />
                    <HeaderNavigation items={navigationHeader} />
                </div>
            </PageContainer>
        </header>
    )
}

export default Header