import type { ILocale } from "./types"

export default {
    header: {
        title: 'Denis M. Developer'
    },
    navigation: {
        portfolio: 'Портфоліо',
        contacts: 'Контакти',
        github: 'GitHub',
    },
    hero: {
        title: 'Frontend Розробник',
        subtitle: 'от 30 000 гр. / міс',
    },
    skills: {
        title: 'Робочі інструменти',
    },
    footer: {
        copyright: '2023, mden.dev. Всі права захищені.',
    },
    locales: {
        ru: 'Русский',
        en: 'English',
        uk: 'Українська'
    },
    contactPage: {
        title: 'Залишити повідомлення',
        description: 'Ви можете залишити своє повідомлення, скориставшись формою або посиланнями, розташованими під нею',
        name: 'Ваше ім\'я',
        email: 'Ваш E-Mail',
        telegram: 'Telegram',
        messageText: 'Текст повідомлення',
        buttonText: 'Надіслати повідомлення',
    },
    works_common: {
        present: 'по теперішній час',
        website: 'Вебсайт',
        exp: 'Досвід роботи'
    },
    contacts: {
        title: 'Зв\'язатися зі мною',
    },
    works: {
        sinergium: {
            name: 'Синергиум',
            city: 'Росія, м. Воронеж',
            info: {
                position: [
                    {
                        position_name: 'Frontend розробник (Trainee)',
                        position_description: 'Стажування як Frontend розробника'
                    },
                    {
                        position_name: 'Frontend разработчик (Junior)',
                        position_description: 'Підтримка проектів компанії, збирання нових проектів на базі існуючих'
                    },
                    {
                        position_name: 'Team Lead Fullstack Developer',
                        position_description: "Управління командою з п'яти розробників, розподіл завдань, коригування робочих процесів, взаємодія з суміжними відділами, планування розвитку співробітників, код-рев'ю, оцінка ефективності команди, допомога з вирішенням проблем під час виконання завдань",
                    },
                    {
                        position_name: 'Керівник відділу програмування',
                        position_description: 'Управління відділом програмування щодо виконання планових завдань, оцифрування бізнесу, розробка та збір звітності, оптимізація процесів. Управління персоналом.'
                    }
                ]
            }
        },
        asiaoptom: {
            name: 'AsiaOptom — Товари оптом з Китаю',
            city: 'Росія, м. Магнітогорськ',
            info: {
                position: [
                    {
                        position_name: 'Lead Frontend розробник',
                        position_description: 'Робота з новими співробітниками, допомога у зануренні у робочі процеси, суміщення з минулою позицією'
                    },
                    {
                        position_name: 'Frontend розробник',
                        position_description: 'Робота з UI/UX дизайнерами, взаємодія з бекендом розробниками при реалізації проектів різної складності. Допомога при побудові бізнес-процесів, редизайн існуючих ресурсів в ініціативному порядку. Робота з макетами у Figma, Sketch та Photoshop, адаптивна верстка лендингів та маркетплейсів'
                    }
                ]
            }
        },
        borkover: {
            city: 'Росія, м. Єкатеринбург',
            info: {
                position: [
                    {
                        position_name: 'Frontend розробник',
                        position_description: 'Верстка сторінок на замовлення, відрядна робота'
                    }
                ]
            }
        },
        uralkam: {
            name: 'ООО "УралКам"',
            city: 'Росія, м. Магнітогорськ',
            info: {
                position: [
                    {
                        position_name: 'Frontend розробник',
                        position_description: 'Відрядна робота, підтримка сайту, точкові оновлення, WordPress'
                    }
                ]
            }
        },
        menumake: {
            city: 'Росія, м. Москва',
            info: {
                position: [
                    {
                        position_name: 'Веб розробник',
                        position_description: 'Розробка першої версії програми для генерації wiki-меню груп ВК з картинки'
                    }
                ]
            }
        },
        brandomatic: {
            name: 'Brandomatic',
            city: 'Росія, м. Москва',
            info: {
                position: [
                    {
                        position_name: 'Співробітник технічної підтримки / Верстальник',
                        position_description: 'Технічна підтримка власників інтернет-майданчиків під час інтеграції з рекламною платформою брендування'
                    }
                ]
            }
        },
        cpkimr: {
            name: 'Управління освіти м. Магнітогорська. Центр підвищення кваліфікації та навчально-методичної роботи. Відділ інформатизації',
            city: 'Росія, м. Магнітогорськ',
            info: {
                position: [
                    {
                        position_name: 'Системний адміністратор',
                        position_description: 'Проведення відеоконференцій, обслуговування комп\'ютерного парку'
                    }
                ]
            }
        }
    },
    notFound: {
        title: 'Сторінка не знайдена',
        description: 'Вибачте, сторінка, яку ви запитуєте, не існує або була видалена',
        link: 'Повернутися на головну'
    },
    dateTime: {
        days: ['Понеділок', 'Вівторок', 'Середа', 'Четвер', 'П\'ятниця', 'Субота', 'Неділя'],
        months: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
    }
} as unknown as ILocale