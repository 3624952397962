import React, { useContext } from 'react'
import { LocaleContext } from '../..'
import PageContainer from '../PageContainer'
import styles from './Footer.module.scss'
import Logo from '../UI/Logo'
import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'



const Footer = ({...props}) => {
    const localeControls = useContext(LocaleContext)
    const localeStrings: any = localeControls.locale.getString('footer')
    
    return (
        <footer className={styles.Footer}>
            <PageContainer>
                <div className={styles.Footer__wrapper}>
                    <div className={styles.Footer__top}>
                        <div className={styles.Footer__top__logo}>
                            <Logo type='big' />
                        </div>
                        <ul className={styles.Footer__top__social}>
                            <li className={styles.Footer__top__social__item}>
                                <Tippy content='@vkgrd' theme='light'>
                                    <a className={styles.Footer__top__social__link} href='https://instagram.com/vkgrd' rel='noreferrer' target='_blank'><i className='fab fa-instagram' /></a>
                                </Tippy>
                            </li>
                            <li className={styles.Footer__top__social__item}>
                                <Tippy content='@vkdg' theme='light'>
                                    <a className={styles.Footer__top__social__link} href='https://github.com/vkdg' rel='noreferrer' target='_blank'><i className='fab fa-github' /></a>
                                </Tippy>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.Footer__copyright}>&copy; {localeStrings.copyright}</div>
                </div>
            </PageContainer>
        </footer>
    )
}

export default Footer
