import styles from './Buttons.module.scss'

type ButtonParameters = {
    type?: "button" | "submit" | "reset" | undefined,
    theme?: "primary" | "alert" | "link" | undefined,
    onclickFunction?: VoidFunction | any,
    icon?: string,
    disabled?: boolean,
    children: any,
}

/**
 * 
 * @param theme {string} тема оформления
 * @param type {string} тип кнопки
 * @param disabled {boolean} флаг активности кнопки
 * @param icon {string} классы из font-awersome для иконки в кнопке 
 * @param onclickFunction {function} функция при нажатии
 * 
 * @returns button element
 */
export const Button = ({
    theme,
    type = 'button',
    disabled = false,
    icon,
    onclickFunction,
    ...props
}: ButtonParameters
) => {
    const buttonClasses = [styles.Button]

    if (theme) buttonClasses.push(styles[`Button__theme__${theme}`])

    return (
        <button 
            className={buttonClasses.join(' ')}
            type={type}
            disabled={disabled}
            onClick={onclickFunction}
        >
            { icon ? <i className={icon} /> : null }
            { props.children }
        </button>
    )
}