import { Link } from 'react-router-dom'
import PageContainer from '../PageContainer'
import styles from './NotFound.module.scss'
import { LocaleContext } from '../..'
import { useContext } from 'react'

const NotFound = ({...props}) => {
    const localeControls = useContext(LocaleContext)
    const strings: any = localeControls.locale.getString('notFound')

    return (
        <section className={styles.NotFound}>
            <PageContainer elementStyles={{height: '100%'}}>
                <div className={styles.NotFound__wrapper}>
                    <div className={styles.NotFound__image} />
                    <div className={styles.NotFound__title}>{strings.title}</div>
                    <div className={styles.NotFound__description}>{strings.description}</div>
                    <Link className={styles.NotFound__link} to='/'>{strings.link}</Link>
                </div>
            </PageContainer>
        </section>
    )
}

export default NotFound