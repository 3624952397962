import LocalizedStrings from "localized-strings"
import { ILocale } from "./types"
import en from './en'
import ru from './ru'
import uk from './uk'

export const fromLocalStorage = ():string | null => localStorage.getItem('lang')

const locales: ILocale = new LocalizedStrings({en, ru, uk})

export default locales

export * from './types'