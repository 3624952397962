import styles from './ContactPage.module.scss'
import PageContainer from '../PageContainer'
import Contacts from '../Contacts'

import { InputText, Textarea } from '../UI/Inputs'
import { Button } from '../UI/Buttons'

import { LocaleContext } from '../..'
import { useContext } from 'react'

const ContactPage = ({...props}) => {
    const localeControls = useContext(LocaleContext)
    const localeStrings:any = localeControls.locale.getString('contactPage')

    return (
        <div className={styles.ContactPage}>
            <PageContainer>
                <div className={styles.ContactPage__content}>
                    <h2 className={styles.ContactPage__title}>{ localeStrings.title }</h2>
                    <p className={styles.ContactPage__description}>{ localeStrings.description }</p>
                    <form className={styles.ContactPage__form} method='post'>
                        <div className={styles.ContactPage__content__double}>
                            <div className={styles.ContactPage__content__row}>
                                <InputText name='name' label={localeStrings.name} />
                            </div>
                            <div className={styles.ContactPage__content__row}>
                                <InputText name='email' label={localeStrings.email} />
                            </div>
                            <div className={[styles.ContactPage__content__row, styles.ContactPage__content__row__extended].join(' ')}>
                                <InputText name='email' label={localeStrings.telegram} />
                            </div>
                        </div>
                        <div className={styles.ContactPage__content__row}>
                            <Textarea name='message' label={localeStrings.messageText} />
                        </div>
                        <div className={styles.ContactPage__content__row}>
                            <Button theme='primary' onclickFunction={() => { console.log('hello world') }}>{localeStrings.buttonText}</Button>
                        </div>
                    </form>
                </div>
                
            </PageContainer>
            <Contacts />
        </div>
    )
}

export default ContactPage