import styles from './Works.module.scss'
import { TProjectItems } from '../../Types/types'
import PageContainer from '../PageContainer'
import WorksItem from '../WorksItem'
import AOLogo from '../../Resources/works/asiaoptom.svg'
import BrandomaticLogo from '../../Resources/works/brandomatic.jpg'
import UralkamLogo from '../../Resources/works/uralkam.jpg'
import BorkoverLogo from '../../Resources/works/borkover.jpg'
import CPKIMGLogo from '../../Resources/works/cpkimr.jpg'
import Sinergium from '../../Resources/works/sinergium.svg'

import { LocaleContext } from '../..'
import { useContext } from 'react'

const Works = ({ ...props }) => {
    const localeControls = useContext(LocaleContext)
    const strings: any = localeControls.locale.getString('works')
    const commonStrings: any = localeControls.locale.getString('works_common')

    const workItems: TProjectItems[] = [
        {
            logo: Sinergium,
            name: strings.sinergium.name,
            city: strings.sinergium.city,
            website: 'https://sinergium.ru',
            info: {
                position: [
                    {
                        position_name: strings.sinergium.info.position[3].position_name,
                        position_start_date: '2023-01',
                        position_description: strings.sinergium.info.position[3].position_description,
                    },
                    {
                        position_name: strings.sinergium.info.position[2].position_name,
                        position_start_date: '2022-09',
                        position_end_date: '2023-01',
                        position_description: strings.sinergium.info.position[2].position_description,
                    },
                    {
                        position_name: strings.sinergium.info.position[1].position_name,
                        position_start_date: '2022-07',
                        position_end_date: '2022-09',
                        position_description: strings.sinergium.info.position[1].position_description,
                    },
                    {
                        position_name: strings.sinergium.info.position[0].position_name,
                        position_start_date: '2022-06',
                        position_end_date: '2022-07',
                        position_description: strings.sinergium.info.position[0].position_description
                    }
                ]
            }
        },
        {
            logo: AOLogo,
            name: strings.asiaoptom.name,
            city: strings.asiaoptom.city,
            website: 'https://asiaoptom.com/',
            info: {
                position: [
                    {
                        position_name: strings.asiaoptom.info.position[0].position_name,
                        position_start_date: '2020-04',
                        position_end_date: '2022-03',
                        position_description: strings.asiaoptom.info.position[0].position_description
                    },
                    {
                        position_name: strings.asiaoptom.info.position[1].position_name,
                        position_start_date: '2016-02',
                        position_end_date: '2020-04',
                        position_description: strings.asiaoptom.info.position[1].position_description
                    },
                ]
            }
        },
        {
            logo: BorkoverLogo,
            name: 'Borkover',
            city: strings.borkover.city,
            website: 'https://borkover.ru/',
            info: {
                position: [
                    {
                        position_name: strings.borkover.info.position[0].position_name,
                        position_start_date: '2017-06',
                        position_end_date: '2017-07',
                        position_description: strings.borkover.info.position[0].position_description
                    }
                ]
            }
        },
        {
            logo: UralkamLogo,
            name: strings.uralkam.name,
            city: strings.uralkam.city,
            website: 'https://uralkam.com/',
            info: {
                position: [
                    {
                        position_name: strings.uralkam.info.position[0].position_name,
                        position_start_date: '2014-03',
                        position_end_date: '2017-01',
                        position_description: strings.uralkam.info.position[0].position_description
                    }
                ]
            }
        },
        {
            name: 'MenuMake',
            city: strings.menumake.city,
            info: {
                position: [
                    {
                        position_name: strings.menumake.info.position[0].position_name,
                        position_start_date: '2015-10',
                        position_end_date: '2016-04',
                        position_description: strings.menumake.info.position[0].position_description
                    }
                ]
            }
        },
        {
            logo: BrandomaticLogo,
            name: strings.brandomatic.name,
            city: strings.brandomatic.city,
            info: {
                position: [
                    {
                        position_name: strings.brandomatic.info.position[0].position_name,
                        position_start_date: '2015-03',
                        position_end_date: '2015-08',
                        position_description: strings.brandomatic.info.position[0].position_description
                    }
                ]
            }
        },
        {
            logo: CPKIMGLogo,
            name: strings.cpkimr.name,
            city: strings.cpkimr.city,
            website: 'https://cpkimr.ru',
            info: {
                position: [
                    {
                        position_name: strings.cpkimr.info.position[0].position_name,
                        position_start_date: '2012-02',
                        position_end_date: '2013-08',
                        position_description: strings.cpkimr.info.position[0].position_description
                    }
                ]
            }
        },
    ]
    const preparePositionsData = (workItems: TProjectItems[]): void => {
        workItems.forEach((workItem: any) => {
            workItem.info.position.forEach((position: any) => {

                let positionStartTime: number | boolean = false;
                let positionEndTime: number | boolean = false;

                if (position.position_start_date) {
                    positionStartTime = Date.parse(position.position_start_date);
                    let readable: string[] = new Date(positionStartTime).toLocaleString('ru').split('.');
                    position.position_start_date_readable = `${readable[1]}.${readable[2].split(',')[0]}`;
                }

                if (position.position_end_date) {
                    positionEndTime = Date.parse(position.position_end_date);
                    let readable: string[] = new Date(positionEndTime).toLocaleString('ru').split('.');
                    position.position_end_date_readable = `${readable[1]}.${readable[2].split(',')[0]}`;
                }
            })
        })
    }
    preparePositionsData(workItems)

    return (
        <section className={styles.Works}>
            <PageContainer>
                <div className={styles.Works__title}>{commonStrings.exp}</div>
                <div className={styles.Works__items}>
                    {
                        workItems.map((work, index) => {
                            return (
                                <WorksItem
                                    key={index}
                                    logo={work.logo}
                                    name={work.name}
                                    website={work.website}
                                    city={work.city}
                                    info={work.info}
                                    commonStrings={commonStrings}
                                />
                            )
                        })
                    }
                </div>
            </PageContainer>
        </section>
    )
}

export default Works