import React, { useState } from 'react'
import styles from './Typography.module.scss'

const Typography = ({...props}) => {
    return (
        <div className={styles.Typography}>
            { props.children }    
        </div>
    )
}


type TextSpoilerProps = {
    label: string,
    children: any
}
export const TextSpoiler = ({
    label,
    children,
    ...props
}: TextSpoilerProps) => {
    let showLabel = label
    let spoilerIconClass = 'fas fa-chevron-down'

    const [visible, setVisible] = useState(false)
    const spoilerClasses = [styles.Spoiler]
    

    if (visible) {
        spoilerClasses.push(styles.Spoiler__show)
        spoilerIconClass = 'fas fa-chevron-up'
        showLabel = 'Скрыть'
    } else {
        showLabel = label
    }

    return (
        <div className={spoilerClasses.join(' ')}>
            <hr />
            <div className={styles.Spoiler__button} onClick={() => { setVisible(!visible) }}>{showLabel} <i className={spoilerIconClass} /></div>
            <div className={styles.Spoiler__content}>{children}</div>
            <hr />
        </div>
    )
}

type SublinksProps = {
    label?: string,
    links: {
        title: string,
        subtitle: string,
        link: string,
    }[]
}
export const Sublinks = ({
    label,
    links,
    ...props
}:SublinksProps) => {
    return (
        <div className={styles.Sublinks}>
            <div className={styles.Sublinks__label}>{label}</div>
            <ul className={styles.Sublinks__list}>
            {
                links.map((link, index) => {
                    return (
                        <li className={styles.Sublinks__list__item} key={index}>
                            <a href={link.link}>
                                <strong>{link.title}</strong> {link.subtitle}
                            </a>
                        </li>
                    )
                })
            }
            </ul>
        </div>
    )
}

export default Typography