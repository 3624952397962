import styles from './WorksItem.module.scss'
import React from 'react'

type IComponentProps = {
    logo?: string,
    name: string,
    city?: string,
    website?: string,
    commonStrings: any,
    info: {
        position: {[key: string]: string}[]
    }
}

const WorksItem = ({
    logo,
    name,
    website,
    city,
    info,
    commonStrings,
    ...props
}: IComponentProps) => {
    console.log(commonStrings);
    return (
        <div className={styles.WorksItem}>
            <div className={styles.WorksItem__logo}>
                {
                    logo ? <img src={logo} alt={name} /> : <div className={[styles.WorksItem__logo, styles.WorksItem__logo__empty].join(' ')} />
                }
            </div>
            {
                info.position.length === 1 ?
                    <div className={styles.WorksItem__info}>
                        <div className={styles.WorksItem__company}>{name}</div>
                        { website ? <a href={website} target='_blank' rel='noreferrer' className={styles.WorksItem__website}><i className='fas fa-link' />Website</a> : null }
                        { city ? <div className={styles.WorksItem__city}>{city}</div> : null}
                        <div className={styles.WorksItem__position}>{info.position[0].position_name}</div>
                        <div className={styles.WorksItem__time}>
                            {
                                info.position[0].position_end_date_readable
                                ? [info.position[0].position_start_date_readable, info.position[0].position_end_date_readable].join(' - ')
                                : [info.position[0].position_start_date_readable, commonStrings.present].join(' - ')
                            }
                        </div>
                        { info.position[0].position_description ? <div className={styles.WorksItem__description}>{info.position[0].position_description}</div> : null }
                        
                    </div>
                : 
                    <div className={[styles.WorksItem__info, styles.WorksItem__info__big].join(' ')}>
                        <div className={styles.WorksItem__company__big}>{name}</div>
                        { website ? <a href={website} target='_blank' rel='noreferrer' className={styles.WorksItem__website}><i className='fas fa-link' />Website</a> : null }
                        { city ? <div className={styles.WorksItem__city}>{city}</div> : null }
                        {
                            info.position.map((position, index) => {
                                return (
                                    <div className={styles.WorksItem__positionItem} key={index}>
                                        <div className={styles.WorksItem__position}>{position.position_name}</div>
                                        <div className={styles.WorksItem__time}>
                                            {
                                                position.position_end_date_readable
                                                ? [position.position_start_date_readable, position.position_end_date_readable].join(' - ')
                                                : [position.position_start_date_readable, commonStrings.present].join(' - ')
                                            }
                                        </div>
                                        { position.position_description ? <div className={styles.WorksItem__description}>{position.position_description}</div> : null }
                                    </div>
                                )
                            })
                        }
                    </div>
            }
        </div>
    )
}

export default WorksItem