import styles from './HeaderNavigation.module.scss'
import { Link, NavLink } from 'react-router-dom'
import LanguageSelector from '../LanguageSelector'

const HeaderNavigation = ({...props}) => {
    const navigationHeader = props.items
    return (
        <div className={styles.HeaderNavigation}>
            {
                navigationHeader.items.map((link: any) => {
                    if(link.visible || link.visible === undefined) {
                        switch(link.type) {
                            case 'a': return (
                                <a 
                                    className={styles.HeaderNavigation__link} 
                                    href={link.to} 
                                    key={link.id} 
                                    target={link.target}
                                >
                                    {link.icon ? <i className={[link.icon, styles.HeaderNavigation__link__icon].join(' ')} /> : null} {link.title}
                                </a>
                            )
                            case 'link': return (
                                <Link 
                                    className={styles.HeaderNavigation__link} 
                                    to={link.to} 
                                    key={link.id}
                                >
                                    {link.icon ? <i className={[link.icon, styles.HeaderNavigation__link__icon].join(' ')} /> : null} {link.title}
                                </Link>
                            )
                            case 'navlink': return (
                                <NavLink 
                                    className={styles.HeaderNavigation__link} 
                                    to={link.to} 
                                    key={link.id}
                                >
                                    {link.icon ? <i className={[link.icon, styles.HeaderNavigation__link__icon].join(' ')} /> : null} {link.title}
                                </NavLink>
                            )
                            default: return null
                        }
                    } else {
                        return null
                    }
                })
            }
            <LanguageSelector />
        </div>
        
    )
}

export default HeaderNavigation