import React from 'react'
import styles from './PageContainer.module.scss'

type TPageContainerProps = {
    elementStyles?: {[key: string]: string},
    elementClasses?: [string],
    children: any
}

const PageContainer = ({
    elementStyles, 
    elementClasses,
    children,
    ...props
}: TPageContainerProps) => {
    let containerClasses = [styles.PageContainer]
    
    if (elementClasses) containerClasses = containerClasses.concat(elementClasses)

    return (
        <div className={containerClasses.join(' ')} style={elementStyles}>{children}</div>
    )
}

export default PageContainer