import type { ILocale } from "./types"

export default {
    header: {
        title: 'Denis M. Developer',
    },
    navigation: {
        portfolio: 'Портфолио',
        contacts: 'Контакты',
        github: 'GitHub',
    },
    hero: {
        title: 'Frontend Разработчик',
        subtitle: 'от 120 000 руб. / мес',
    },
    skills: {
        title: 'Рабочие инструменты',
    },
    footer: {
        copyright: '2023, mden.dev. Все права защищены.',
    },
    locales: {
        ru: 'Русский',
        en: 'English',
        uk: 'Українська'
    },
    contactPage: {
        title: 'Оставить сообщение',
        description: 'Вы можете оставить свое сообщение, воспользовавшись формой или ссылками, расположенными под ней',
        name: 'Ваше имя',
        email: 'Ваш E-Mail',
        telegram: 'Telegram',
        messageText: 'Текст сообщения',
        buttonText: 'Отправить сообщение',
    },
    works_common: {
        present: 'по настоящее время',
        website: 'Вебсайт',
        exp: 'Опыт работы'
    },
    contacts: {
        title: 'Связаться со мной',
    },
    works: {
        sinergium: {
            name: 'Синергиум',
            city: 'Россия, г. Воронеж',
            info: {
                position: [
                    {
                        position_name: 'Frontend разработчик (Trainee)',
                        position_description: 'Стажировка в качестве Frontend разработчика'
                    },
                    {
                        position_name: 'Frontend разработчик (Junior)',
                        position_description: 'Поддержка проектов компании, сборка новых проектов на базе существующих'
                    },
                    {
                        position_name: 'Team Lead Fullstack Developer',
                        position_description: 'Управление командой из пяти разработчиков, распределение задач, корректировка рабочих процессов, взаимодействие со смежными отделами, планирование развития сотрудников, код-ревью, оценка эффективности команды, помощь с решением проблем при выполнении задач',
                    },
                    {
                        position_name: 'Руководитель отдела программирования',
                        position_description: 'Управление отделом программирования в части выполнения плановых задач, оцифровка бизнеса, разработка и сбор отчетности, оптимизация процессов. Управление персоналом.'
                    }
                ]
            }
        },
        asiaoptom: {
            name: 'AsiaOptom — Товары оптом из Китая',
            city: 'Россия, г. Магнитогорск',
            info: {
                position: [
                    {
                        position_name: 'Ведущий Frontend разработчик',
                        position_description: 'Работа с новыми сотрудниками, помощь в погружении в рабочие процессы, совмещение с прошлой позицией'
                    },
                    {
                        position_name: 'Frontend разработчик',
                        position_description: 'Работа с UI/UX дизайнерами, взаимодействие с бэкенд разработчиками при реализации проектов различной сложности. Помощь при выстраивании бизнес-процессов, редизайн существующих ресурсов в инициативном порядке. Работа с макетами в Figma, Sketch и Photoshop, адаптивная верстка лендингов и маркетплейсов'
                    }
                ]
            }
        },
        borkover: {
            city: 'Россия, г. Екатеринбург',
            info: {
                position: [
                    {
                        position_name: 'Frontend разработчик',
                        position_description: 'Верстка страниц на заказ, сдельная работа'
                    }
                ]
            }
        },
        uralkam: {
            name: 'ООО "УралКам"',
            city: 'Россия, г. Магнитогорск',
            info: {
                position: [
                    {
                        position_name: 'Frontend разработчик',
                        position_description: 'Сдельная работа, поддержка сайта, точечные обновления, WordPress'
                    }
                ]
            }
        },
        menumake: {
            city: 'Россия, г. Москва',
            info: {
                position: [
                    {
                        position_name: 'Веб разработчик',
                        position_description: 'Разработка первой версии приложения для генерации wiki-меню групп ВК из картинки'
                    }
                ]
            }
        },
        brandomatic: {
            name: 'Brandomatic',
            city: 'Россия, г. Москва',
            info: {
                position: [
                    {
                        position_name: 'Сотрудник технической поддержки / Верстальщик',
                        position_description: 'Техническая поддержка владельцев интернет-площадок при интеграции с рекламной платформой брендирования'
                    }
                ]
            }
        },
        cpkimr: {
            name: 'Управление образования г. Магнитогорска. Центр повышения квалификации и учебно-методической работы. Отдел информатизации',
            city: 'Россия, г. Магнитогорск',
            info: {
                position: [
                    {
                        position_name: 'Системный администратор',
                        position_description: 'Проведение видеоконференций, обслуживание компьютерного парка'
                    }
                ]
            }
        }
    },
    notFound: {
        title: 'Страница не найдена',
        description: 'Извините, страница которую вы запрашиваете не существует или была удалена',
        link: 'Вернуться на главную'
    },
    dateTime: {
        days: ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'],
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    }
} as unknown as ILocale