import styles from './Logo.module.scss'
import { Link } from 'react-router-dom'

const Logo = ({...props}) => {
    switch (props.type) {
        case 'big':
            return (
                <Link to='/' className={[styles.Logo, styles.Logo__big].join(' ')}>
                    <div className={styles.Logo__icon} />
                </Link>
            )
        default: 
            return (
                <Link to='/' className={styles.Logo}>
                    <div className={styles.Logo__icon} />
                    <div className={styles.Logo__text}>{props.title}</div>
                </Link>
            )
    }
}

export default Logo