import styles from './Inputs.module.scss'

function onFocusHandler({...params}) {   
    const targetElement = params.target
    const targetElementParent = targetElement.parentNode
    const targetElementLabel = targetElementParent.querySelector(`.${styles.Input__label}`)

    if (targetElementLabel) targetElementLabel.classList.add(`${styles['Input__label__has-text']}`)
}

function onBlurHandler({...params}) {
    const targetElement = params.target
    const targetElementParent = targetElement.parentNode
    const targetElementLabel = targetElementParent.querySelector(`.${styles.Input__label}`)

    if (targetElementLabel) {
        if (targetElement.value.trim() === '') {
            targetElementLabel.classList.remove(`${styles['Input__label__has-text']}`)
        }
    }
}

function onInputTextareaHandler({...params}) {
    const thisTextarea = params.target
    thisTextarea.style.height = 'auto'
    thisTextarea.style.height = (thisTextarea.scrollHeight) + 'px'
}


interface InputProps {
    label?: string,
    name?: string,
    value?: string,
    disabled?: boolean,
    placeholder?: string,
    elementStyles?: {[key:string]: string},
    error?: boolean,
}

interface TextareaProps extends InputProps {
    rows?: number
}

interface RadioProps {
    label: string,
    name?: string,
    value: string,
    theme?: string,
    required?: boolean,
    error?: boolean,
    elementStyles?: {[key:string]: string},
}

export const InputText = ({
    label,
    name,
    value,
    disabled,
    placeholder,
    elementStyles,
    error,
    ...props
}:InputProps) => {
    const inputClasses = [styles.Input]
    const inputLabelClasses = [styles.Input__label]
    if (error) inputClasses.push(styles.Input__error)
    if (disabled) inputClasses.push(styles.Input__disabled)
    if (value) inputLabelClasses.push(styles['Input__label__has-text'])
    return (
        <label className={inputClasses.join(' ')} style={elementStyles}>
            { label ? <div className={inputLabelClasses.join(' ')}>{label}</div> : null }
            <input 
                type='text' 
                placeholder={placeholder} 
                onFocus={onFocusHandler} 
                onBlur={onBlurHandler} 
                name={name} 
                disabled={disabled} 
                className={styles.Input__text__field} 
                defaultValue={value}
            />
        </label>
    )
}

export const Textarea = ({
    label,
    name,
    value,
    disabled,
    placeholder,
    rows = 1,
    elementStyles,
    error,
    ...props
}: TextareaProps) => {
    const inputClasses = [styles.Input, styles.Input__inputTextarea]
    const inputLabelClasses = [styles.Input__label]
    if (error) inputClasses.push(styles.Input__error)
    if (disabled) inputClasses.push(styles.Input__disabled)
    if (value) inputLabelClasses.push(styles['Input__label__has-text'])
    return (
        <label className={inputClasses.join(' ')} style={elementStyles}>
            { label ? <div className={inputLabelClasses.join(' ')}>{label}</div> : null }
            <textarea 
                placeholder={placeholder} 
                rows={rows} 
                onFocus={onFocusHandler} 
                onInput={onInputTextareaHandler} 
                onBlur={onBlurHandler} 
                name={name} 
                disabled={disabled} 
                className={styles.Input__textarea__field}
                defaultValue={value}
            ></textarea>
        </label>
    )
}

export const RadioInput = ({
    label,
    name,
    value,
    required,
    elementStyles,
    theme,
    error,
    ...props
}: RadioProps) => {
    const radioClasses = [styles.Input__inputRadio]
    if (error) radioClasses.push(styles.Input__inputRadio__error)
    return (
        <label className={radioClasses.join(' ')} style={elementStyles}>
            <input className={styles.Input__inputRadio__input} type='radio' name={name} required={required} value={value} />
            <div className={styles.Input__inputRadio__ellipse} />
            <div className={styles.Input__inputRadio__label}>{label}</div>
        </label>
    )
}

export const CheckboxInput = ({
    label,
    name,
    value,
    required,
    theme,
    elementStyles,
    error,
    ...props
}: RadioProps) => {
    const checkboxesClasses = [styles.Input__inputCheckbox]
    if (error) checkboxesClasses.push(styles.Input__inputCheckbox__error)
    return (
        <label className={checkboxesClasses.join(' ')} style={elementStyles}>
            <input className={styles.Input__inputCheckbox__input} type='checkbox' name={name} required={required} value={value} />   
            <div className={styles.Input__inputCheckbox__square} />
            <div className={styles.Input__inputCheckbox__label}>{label}</div>
        </label>
    )
}