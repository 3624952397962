import { useContext, useState } from "react"
import { LocaleContext } from "../../.."
import styles from './LanguageSelector.module.scss'

const LanguageSelector = ({...props}) => {
    const [visible, setVisible] = useState(false)

    const localeControls = useContext(LocaleContext)
    const availableLanguages = localeControls.locale.getAvailableLanguages();

    const variantsClasses = [styles.LanguageSelector__variants]

    function setLocaleHandler(localeKey: string) {
        localeControls.setLocale(localeKey)
        setVisible(false)
    }
    
    if (visible) variantsClasses.push(styles.LanguageSelector__variants__show);
    return (
        <div className={styles.LanguageSelector}>
            <div className={styles.LanguageSelector__selector} onClick={() => { setVisible(!visible); }}><div className={styles.LanguageSelector__selector__icon} /></div>
            <div className={variantsClasses.join(' ')}>
                {
                    availableLanguages.map((item, index) => {
                        const localeClasses = [styles.LanguageSelector__variants__item, styles[`LanguageSelector__${item}`]]
                        return (
                            <div key={index} onClick={() => {setLocaleHandler(item)}} className={localeClasses.join(' ')}>{localeControls.locale.getString('locales.' + item)}</div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default LanguageSelector