import { useContext } from 'react'
import PageContainer from '../PageContainer'
import styles from './Hero.module.scss'

import { LocaleContext } from '../..'

const Hero = ({...props}) => {
    const localeControls = useContext(LocaleContext)
    const localeStrings: any = localeControls.locale.getString('hero')

    return (
        <section className={styles.Hero}>
            <PageContainer>
                <div className={styles.Hero__wrapper}>
                    <div className={styles.Hero__logo} />
                    <h2 className={styles.Hero__title}>{localeStrings.title}</h2>
                    {/* <h3 className={styles.Hero__subtitle}>{localeStrings.subtitle}</h3> */}
                </div>
            </PageContainer>
        </section>
    )
}

export default Hero