import styles from './Contacts.module.scss'
import PageContainer from '../PageContainer'

import { LocaleContext } from '../..'
import { useContext } from 'react'

const Contacts = ({...props}) => {
    const localeControls = useContext(LocaleContext)
    const localeStrings:any = localeControls.locale.getString('contacts')

    return (
        <section className={styles.Contacts}>
            <PageContainer elementStyles={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <div className={styles.Contacts__wrapper}>
                    <div className={styles.Contacts__title}>{ localeStrings.title }</div>
                    <div className={styles.Contacts__list}>
                        <a href='https://t.me/exportDefault' target='_blank' rel='noreferrer' className={styles.Contacts__item}><i className='fab fa-telegram' /></a>
                        <a href='skype:aaron.shiver/chat' className={styles.Contacts__item}><i className='fab fa-skype' /></a>
                        <a href='https://linkedin.com/in/vkgrd' target='_blank' rel='noreferrer' className={styles.Contacts__item}><i className='fab fa-linkedin' /></a>
                    </div>
                </div>
            </PageContainer>
        </section>
    )
}

export default Contacts