import type { ILocale } from "./types"

export default {
    header: {
        title: 'Denis M. Developer',
    },
    navigation: {
        portfolio: 'Portfolio',
        contacts: 'Contacts',
        github: 'GitHub',
    },
    hero: {
        title: 'Frontend Developer',
        subtitle: 'From USD 1 000 / Month',
    },
    skills: {
        title: 'Developer Workflow',
    },
    footer: {
        copyright: '2023, mden.dev. All Rights Reserved.',
    },
    locales: {
        ru: 'Русский',
        en: 'English',
        uk: 'Українська'
    },
    contactPage: {
        title: 'Message Form',
        description: 'You can leave your message using the form or the links below',
        name: 'Name',
        email: 'E-Mail',
        telegram: 'Telegram',
        messageText: 'Message text',
        buttonText: 'Send message',
    },
    works_common: {
        present: 'present',
        website: 'website',
        exp: 'Experience',
    },
    contacts: {
        title: 'Get In Touch',
    },
    works: {
        sinergium: {
            name: 'Sinergium',
            city: 'Voronezh, Russia',
            info: {
                position: [
                    {
                        position_name: 'Trainee Frontend Developer',
                        position_description: 'Internship as a front-end developer'
                    },
                    {
                        position_name: 'Junior Frontend Developer',
                        position_description: 'Support for company projects, assembling new projects based on existing ones'
                    },
                    {
                        position_name: 'Team Lead Fullstack Developer',
                        position_description: 'Management of a team of five developers, distribution of tasks, adjustment of work processes, interaction with related departments, employee development planning, code review, team performance assessment, assistance with solving problems during tasks',
                    },
                    {
                        position_name: 'Head of Development',
                        position_description: 'Management of the programming department in terms of the implementation of planned tasks, business digitization, development and collection of reports, process optimization. Personnel Management.'
                    }
                ]
            }
        },
        asiaoptom: {
            name: 'AsiaOptom',
            city: 'Magnitogorsk, Russia',
            info: {
                position: [
                    {
                        position_name: 'Team Lead Frontend Developer',
                        position_description: 'Work with new employees, assistance in immersion in work processes, reconciliation with the previous position'
                    },
                    {
                        position_name: 'Frontend Developer',
                        position_description: 'Work with UI / UX designers, interaction with back-end developers in the implementation of projects of varying complexity. Assistance in building business processes, redesigning existing resources on an initiative basis. Work with layouts in Figma, Sketch and Photoshop, adaptive layout of landing pages and marketplaces'
                    }
                ]
            }
        },
        borkover: {
            city: 'Yekaterinburg, Russia',
            info: {
                position: [
                    {
                        position_name: 'Frontend Developer',
                        position_description: 'Custom page layout, piece work'
                    }
                ]
            }
        },
        uralkam: {
            name: 'UralKam LLC',
            city: 'Magnitogorsk, Russia',
            info: {
                position: [
                    {
                        position_name: 'Frontend Developer',
                        position_description: 'Piece work, site maintenance, spot updates, WordPress'
                    }
                ]
            }
        },
        menumake: {
            city: 'Moscow, Russia',
            info: {
                position: [
                    {
                        position_name: 'Web Developer',
                        position_description: 'Development of the first version of the application for generating a wiki menu of VK groups from a picture'
                    }
                ]
            }
        },
        brandomatic: {
            name: 'Brandomatic',
            city: 'Moscow, Russia',
            info: {
                position: [
                    {
                        position_name: 'Technical Support Engineer / Layout Developer',
                        position_description: 'Technical support for owners of Internet sites when integrating with an advertising branding platform'
                    }
                ]
            }
        },
        cpkimr: {
            name: 'Department of Education of Magnitogorsk. Center for advanced training and educational and methodical work. Department of Informatization',
            city: 'Magnitogorsk, Russia',
            info: {
                position: [
                    {
                        position_name: 'System Administrator',
                        position_description: 'Video conferencing, computer park maintenance'
                    }
                ]
            }
        }
    },
    notFound: {
        title: 'Page Not Found',
        description: 'Sorry, the page you are requesting does not exist or has been removed',
        link: 'Back to Mainpage'
    },
    dateTime: {
        days: ['Monday', 'Thuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    }
} as unknown as ILocale