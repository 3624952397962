import React from 'react'
import styles from './ContentArea.module.scss'
import { Outlet } from 'react-router-dom'

const ContentArea = ({...props}) => {
    return (
        <div className={styles.ContentArea}>
            <Outlet />
        </div>
    )
}

export default ContentArea