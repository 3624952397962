import PageContainer from '../PageContainer'
import styles from './Skills.module.scss'
import type { TWorkItems } from '../../Types/types'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

import { LocaleContext } from '../..'
import { useContext } from 'react'

export const tools: TWorkItems = [
    {title: 'HTML', icon: 'html'},
    {title: 'CSS', icon: 'css'},
    {title: 'SCSS', icon: 'scss'},
    {title: 'JavaScript', icon: 'js'},
    {title: 'TypeScript', icon: 'ts'},
    {title: 'NodeJS', icon: 'node'},
    {title: 'React', icon: 'react'},
    {title: 'Webpack', icon: 'webpack'},
    {title: 'Django', icon: 'django'},
    {title: 'Python', icon: 'python'},
    {title: 'PHP', icon: 'php'},
    {title: 'GIT', icon: 'git'},
    {title: 'Sketch', icon: 'sketch'},
    {title: 'Figma', icon: 'figma'},
    {title: 'PyCharm', icon: 'pycharm'},
    {title: 'PhpStorm', icon: 'phpstorm'},
    {title: 'VSCode', icon: 'vscode'},
    {title: 'Telegram BOT API', icon: 'telegram'}
]

const Skills = ({...props}) => {
    const localeControls = useContext(LocaleContext)
    const localeStrings:any = localeControls.locale.getString('skills')

    return (
        <div className={styles.Skills}>
            <PageContainer>
                <div className={styles.Skills__title}>{ localeStrings.title }</div>
                <div className={styles.Skills__items}>
                {
                    tools.map((item, index) => {
                        return (
                            <Tippy animation='fade' theme='light' content={item.title} key={index}>
                                <div className={[styles.Skills__item, styles[`Skills__item__${item.icon}`]].join(' ')} />
                            </Tippy>
                        )
                    })
                }
                </div>
            </PageContainer>
        </div>
    )
}

export default Skills